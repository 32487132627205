import isBlank from 'is-blank'

export default {
    isBlank(val) {
        return isBlank(val)
    },
    createErrorFromList(errors) {
        let message = {
            type: 'danger',
            show: true,
            html: 'Network Error.',
            icon: 'fa-warning',
            timeout: 2500,
        }

        message.html = errors.map(item => {
            var el = document.createElement("li");
            el.innerText = el.textContent = item;
            return el.innerHTML;
        })

        message.html = '<ul>Terdapat kesalahan berikut:' + message.html + '</ul>'
        return message
    },
    createError(error) {
        //Quasar Toast Schema
        let message = {
            type: 'danger',
            show: true,
            html: 'Network Error.',
            icon: 'fa-warning',
            timeout: 2500,
        }

        if (typeof error !== 'undefined' && error != null && error.hasOwnProperty('message')) {
            message.html = error.message
        }

        if (typeof error.response !== 'undefined' && error.response != null) {
            //Setup Generic Response Messages
            message.httpStatus = error.response.status
            if (error.response.status === 401) {
                message.html = 'UnAuthorized'
                //vm.$emit('logout') //Emit Logout Event
            } else if (error.response.status === 403) {
                message.html = 'Not authorized to access resource'
            } else if (error.response.status === 404) {
                message.html = 'API Route is Missing or Undefined'
            } else if (error.response.status === 405) {
                message.html = 'API Route Method Not Allowed'
            } else if (error.response.status === 422) {
                //Validation Message
            } else if (error.response.status >= 500) {
                message.html = 'Server Error'
            }

            //Try to Use the Response Message
            if (error.hasOwnProperty('response') && error.response.hasOwnProperty('data')) {
                if (error.response.data.hasOwnProperty('message') && error.response.data.message.length > 0) {
                    message.html = error.response.data.message
                }
            }
        }

        return message;
        //Toast the Message
        //if (message.html.length > 0) {
        //Toast.create.negative(message)
        // }
    },
    changeInitialName(name) {
        let changedName = String()

        name.split(" ").forEach(e => {
            if (changedName.length < 2) changedName += e[0]
        })

        return changedName
    },
    formatTabMessages(message) {
        let temp = message

        // check url
        if (this.stringIsUrl(message)) {
            let checkWhiteSpace = []
            let checkNewLine = []

            temp.split(" ").forEach(e => {
                if (this.stringIsUrl(e) && e.indexOf('\n') == -1) {
                    checkWhiteSpace.push(`<a href='${e}' target='_blank'>${e}</a>`)
                } else {
                    checkWhiteSpace.push(e)
                }
            })

            checkWhiteSpace.join(' ').split("\n").forEach(e => {
                if (this.stringIsUrl(e) && e.indexOf(' ') == -1) {
                    checkNewLine.push(`<a href='${e}' target='_blank'>${e}</a>`)
                } else {
                    checkNewLine.push(e)
                }
            })

            temp = checkNewLine.join('\n')
        }

        let current = true

        // check bold
        const isBold = temp.split('').includes('*')
        if (isBold) {
            temp = temp.split('').map(e => {
                if (e == '*') {
                    if (current) {
                        current = false
                        return '<span style="font-weight: bold;">'
                    } else {
                        current = true
                        return '</span>'
                    }
                }

                return e
            }).join('')
        }

        // check italic
        // const isItalic = temp.split('').includes('_')
        // if(isItalic) {
        //     current = true
        //     temp = temp.split('').map(e => {
        //         if(e == '_') {
        //             if(current) {
        //                 current = false
        //                 return '<i>'
        //             } else {
        //                 current = true
        //                 return '</i>'
        //             }
        //         }

        //         return e
        //     }).join('')
        // }

        // check Strikethrough
        const isStrikethrough = temp.split('').includes('~')
        if (isStrikethrough) {
            current = true
            temp = temp.split('').map(e => {
                if (e == '~') {
                    if (current) {
                        current = false
                        return '<s>'
                    } else {
                        current = true
                        return '</s>'
                    }
                }

                return e
            }).join('')
        }

        return temp ? temp.split('\n').join('<br />') : temp
    },
    stringIsUrl(str) {
        const isUrl = new RegExp('((http|https)://)(www.)?' +
            '[a-zA-Z0-9@:%._\\+~#?&//=]{2,256}\\.[a-z]' +
            '{2,6}\\b([-a-zA-Z0-9@:%._\\+~#?&//=]*)'
        )

        return isUrl.test(str)
    },
}